import Cookies from 'js-cookie';
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import chevron from '../img/nav/chevron.png';
import icon_map from '../img/common/fav_icon_map.png';
import og_img from '../img/og_img.jpg';

document.addEventListener('DOMContentLoaded', () => {
  Fancybox.bind('[data-fancybox]', {
    // Custom options for Fancybox
    // Video: {
    //   autoplay: true,
    //   ratio: 16 / 9,
    // },
  });
});

//  checks to see if JS is enabled on the page. If it does, then no-js will be removed. This statement will allow css background images to be set.

document.documentElement.classList.remove('no-js');

//  replace <a> tag .jpg in media section with .webp files if webp is safe to use.

const isWebpAvailable = document.querySelector('html');

if (isWebpAvailable.classList.contains('webp')) {
  let singleSrc;
  let multilineSrc;
  document.querySelectorAll('[data-fancybox="media"]').forEach(elem => {
    multilineSrc = elem.dataset.srcset.replace(/jpg/g, 'webp').trim();
    singleSrc = elem.dataset.src.replace('jpg', 'webp');
    elem.dataset.srcset = multilineSrc;
    elem.dataset.src = singleSrc;
  });
}

// footer

// makes sure copyright date is always current for the given year.
(function footerDate() {
  const today = new Date(),
    year = today.getFullYear(),
    el = document.querySelector('.footer__date');
  if (el) {
    el.innerHTML = year;
  }
})();

// cookie consent
document.addEventListener('DOMContentLoaded', () => {
  const acceptButton = document.querySelector('.cookie__buttons--accept');
  const rejectButton = document.querySelector('.cookie__buttons--reject');
  const consentBanner = document.querySelector('.cookie');

  // Set a cookie
  const setCookie = (name, value, days) => {
    const expires = new Date(
      Date.now() + days * 24 * 60 * 60 * 1000,
    ).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  };

  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  acceptButton.addEventListener('click', () => {
    setCookie('gtmConsent', 'accepted', 365);
    loadGTM();
    consentBanner.classList.add('cookie__hide');
  });

  // Event listener for "Reject"
  rejectButton.addEventListener('click', () => {
    setCookie('gtmConsent', 'rejected', 1);
    consentBanner.classList.add('cookie__hide');
  });

  // Check consent status on page load
  const gtmConsent = getCookie('gtmConsent');
  if (gtmConsent === 'accepted') {
    loadGTM();
    consentBanner.classList.add('cookie__hide');
  } else if (!gtmConsent) {
    consentBanner.classList.remove('cookie__hide');
  }
});
